import { Indication } from '../../../models/indication';
import { GeneralResponse, GeneralDTO } from '@shared/services/http/general-response/general-response';
import { IndicationList } from '@shared/models/indication-list';

export class IndicationResponse extends GeneralResponse {
    indications: Indication[];

    constructor(indicationsDTO: IndicationsDTO) {
        super(indicationsDTO);
        const indicationList = new IndicationList();
        this.indications = indicationsDTO.indications.map(
            (indicationDTO: IndicationDTO) => new Indication(
                indicationList.getCodeFromId(parseInt(indicationDTO.code, 10)),
                indicationDTO.name,
                parseInt(indicationDTO.code, 10)));
    }
}

export interface IndicationDTO {
    code: string;
    name: string;
}

export interface IndicationsDTO extends GeneralDTO {
    indications: IndicationDTO[];
}
